import React from 'react';

const Footer = () => (
  <footer id="footer" className="container mx-auto py-16 px-6 mt-24 mb-8 text-gray-800">
    <div className="flex -mx-3">
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">About Us</h2>
        <p className="mt-5">
          Eventful is a project that aims to help the world by making every event a better
          experience.
          <br />
          <br />
          <a href="mailto:contact@chocolat.chaud.io" target="_blank" rel="noreferrer noopener">
            contact@chocolat.chaud.io
          </a>
        </p>
      </div>
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Important Links</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <a href="/">Eventful</a>
          </li>
          <li>
            <a href="https://plan.eventful-app.com">Eventful Service</a>
          </li>
          <li>
            <a href="/privacy-policy">Privacy Policy</a>
          </li>
          <li>
            <a href="https://chocolat.chaud.io">Chocolat Chaud Computing Inc.</a>
          </li>
        </ul>
      </div>
      {/* <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Contact Us</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <a href="https://dev.to/changoman">Dev.to</a>
          </li>
          <li>
            <a href="https://twitter.com/HuntaroSan">Twitter</a>
          </li>
          <li>
            <a href="https://github.com/codebushi/gatsby-starter-lander">GitHub</a>
          </li>
        </ul>
      </div> */}
    </div>
  </footer>
);

export default Footer;
